import { Col } from 'react-bootstrap';
import ChangePassword from './ChangePassword';

const Account = () => (
  <div className="account pad-top pad-sides">
    <Col sm={{ span: 10, offset: 1 }} md={{ span: 4, offset: 4 }}>
      <h6>Change Password</h6>
      <div className="pad-top shadow">
        <ChangePassword />
      </div>
    </Col>
  </div>
);

export default Account;
