import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { companyAtom } from '../stores/root.store';
import { getUserTiles } from '../services/NavService';
import { Button, Card, Container } from 'react-bootstrap';
import MarkdownIt from 'markdown-it';
import { useAtom } from 'jotai';

import '../styles/dashboard.scss';
import Api from '../services/ApiService';
import MarkDownEditor from './MarkdownEditor';
import Widgets from './Widgets';
import userAtom from '../stores/user.store';

const mdParser = new MarkdownIt({ html: true, linkify: true });

const Dashboard = () => {
  const [user] = useAtom(userAtom);
  const [company, setCompany] = useAtom(companyAtom);
  const [showEditor, setShowEditor] = useState(false);

  const navigate = useNavigate();

  const canEditDashboard = () => (user.isAdmin || user.isDealer) && company && company.id;

  const saveMarkdown = (md: string) => {
    setShowEditor(false);
    setCompany({ dashboardMd: md });
    return Api.put(`/companies/${company.id}`, { dashboardMd: md });
  };

  const renderTiles = () => {
    if (!user) {
      return null;
    }

    const tiles = getUserTiles(user, company);

    if (!tiles) {
      return null;
    }

    return tiles.map((page, i) => (
      <div className="dashboard-item" key={i}>
        <Card onClick={() => navigate(page.path)} id={`tile-${page.name}`}>
          <Card.Body key={i}>
            <Card.Title style={{ fontSize: '48px' }}>
              <i className={page.icon} />
            </Card.Title>
            {/* <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle> */}
          </Card.Body>
          <Card.Footer>{page.label}</Card.Footer>
        </Card>
      </div>
    ));

    // return tiles.map((page, i) => (
    //   <GridTile key={i} onClick={() => navigate(page.path)} id={`tile-${page.name}`}>
    //     <div className="shadow">
    //       <GridTilePrimary>
    //         <i className={page.icon} />
    //       </GridTilePrimary>
    //       <GridTileSecondary>
    //         <GridTileIcon icon="info" />
    //         <GridTileTitle>{page.label}</GridTileTitle>
    //       </GridTileSecondary>
    //     </div>
    //   </GridTile>
    // ));
  };

  const renderControls = () => {
    if (!canEditDashboard()) {
      return;
    }

    return (
      <>
        <div className="admin-controls">
          <Button onClick={() => setShowEditor(true)}>Edit Content</Button>
        </div>
        {showEditor && (
          <MarkDownEditor onSave={saveMarkdown} onCancel={() => setShowEditor(false)} md={company.dashboardMd} />
        )}
      </>
    );
  };

  return (
    <div className="dashboard">
      <Container fluid>
        <div
          style={{
            display: 'flex',
            flexFlow: 'row wrap',
            justifyContent: 'flex-start',
            gap: '15px',
            marginTop: '15px',
          }}
        >
          {renderTiles()}
        </div>
        <hr />
        <Widgets />
        {!!company.dashboardMd && (
          <div className="p-3" dangerouslySetInnerHTML={{ __html: mdParser.render(company.dashboardMd) }}></div>
        )}
      </Container>
      {renderControls()}
    </div>
  );
};

export default Dashboard;
