import { atom } from 'jotai';

export interface User {
  avatar: string;
  branch: Branch | null;
  branches: Branch[];
  createdAt: string;
  customer?: Customer;
  customers?: Customer[];
  dealer?: Dealer;
  deletedAt: string;
  email: string;
  firstName: string;
  id: number;
  isAdmin: boolean;
  isCustomer: boolean;
  isDealer: boolean;
  lastLogin: string;
  lastName: string;
  updatedAt: string;
}

export interface Branch {
  active: boolean;
  address: string;
  city: string;
  country: string;
  createdAt: string;
  dataAccount: string;
  deletedAt: string;
  deliveryMethods: string[];
  email: string;
  fax: string;
  id: number;
  name: string;
  num: number;
  phone: string;
  shippingTierId: number;
  state: string;
  updatedAt: string;
  zip: string;
  shippingTier: {
    data: { min: number; max: number; shipping: number; handling: number }[];
  };
}

export interface Customer {
  altBranches: any[];
  companyName: string;
  contacts: any[];
  createdAt: string;
  deletedAt: string;
  deliveryMethod: string;
  email: string;
  id: number;
  info: string;
  name: string;
  num: string;
  permissions: any[];
  poRequired: string;
  shippingAccounts: any[];
  status: 'active' | 'inactive';
  updatedAt: string;
}

export interface Dealer {
  createdAt: string;
  deletedAt: string;
  id: number;
  isAdmin: boolean;
  updatedAt: string;
}

const defaultState: User = {
  avatar: '',
  branch: null,
  branches: [],
  createdAt: '',
  deletedAt: '',
  email: '',
  firstName: '',
  id: -1,
  isAdmin: false,
  isCustomer: false,
  isDealer: false,
  lastLogin: '',
  lastName: '',
  updatedAt: '',
};

const userAtom = atom<User>(defaultState);

export default userAtom;
