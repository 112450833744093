import NavPage from './components/NavPage';
import AuthService from './services/AuthService';
import { Route, Routes, BrowserRouter, useLocation, Navigate } from 'react-router-dom';
import App from './App';
import Dashboard from './components/Dashboard';
import Login from './components/login/Login';
import ForgotPassword from './components/login/ForgotPassword';
import ResetPassword from './components/login/ResetPassword';
import Contact from './components/contact/Contact';
import Db from './components/db/Db';
import InviteDealer from './components/InviteDealer';
import InviteCustomer from './components/InviteCustomer';
import Account from './components/user/Account';
import AccountBalance from './components/customers/AccountBalance';
import History from './components/customers/History';
import CurrentCompany from './components/company/CurrentCompany';
import userAtom from './stores/user.store';
import Vehicles from './components/vehicles/Vehicles';
import Register from './components/login/Register';
import OrderDetail from './components/orders/OrderDetail';
import PartOrder from './components/parts/PartOrder';
import Parts from './components/parts/Parts';
import Orders from './components/orders/Orders';
import Promotions from './components/Promotions';
import BackOrders from './components/BackOrders';
import PaymentPage from './components/payment/PaymentPage';
import Test from './components/Test';
import WorldNetReceipt from './components/payment/WorldNetReceipt';
import QuickOrderForm from './components/orders/QuickOrderForm';
import PartsNext from './components/parts/PartsNext';
import { useAtom } from 'jotai';
import WorldNetTokenResponse from './components/payment/WorldNetTokenResponse';
import WorldNetAddCard from './components/payment/WorldNetAddCard';

interface RouteProps {
  children?: any;
}

// This is useful in routes where auth hasn't yet been established.
const AuthRoute = ({ children }: RouteProps) => {
  const location = useLocation();

  if (!AuthService.loggedIn()) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};

const DealerRoute = ({ children }: RouteProps) => {
  const [user] = useAtom(userAtom);
  const location = useLocation();

  if (user && (user.isDealer || user.isAdmin)) {
    return children;
  }

  return <Navigate replace to="/login" state={{ from: location }} />;
};

const NavPageRouter = () => (
  <NavPage>
    <AuthRoute>
      <Routes>
        <Route path="/db/order" element={<Orders />} />
        <Route path="/db/:itemName" element={<Db />} />
        <Route path="/db/:itemName/:itemId" element={<Db />} />
        <Route path="/db/:itemName/:itemId/:action" element={<Db />} />
        <Route path="/invite/dealer" element={<InviteDealer />} />
        <Route path="/invite/customer" element={<InviteCustomer />} />
        <Route path="/account" element={<Account />} />
        <Route path="/account-balance" element={<AccountBalance />} />
        <Route path="/payment/add-card" element={<WorldNetAddCard />} />
        <Route path="/history" element={<History />} />
        <Route path="/parts" element={<Parts />} />
        <Route path="/parts-next" element={<PartsNext />} />
        <Route path="/cart" element={<PartOrder />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/vehicles" element={<Vehicles />} />
        <Route path="/promotions" element={<Promotions />} />
        <Route path="/back-orders" element={<BackOrders />} />
        <Route path="/orders/:orderId" element={<OrderDetail />} />
        <Route
          path="/order-lite"
          element={
            <DealerRoute>
              <QuickOrderForm />
            </DealerRoute>
          }
        />
        <Route
          path="/company"
          element={
            <DealerRoute>
              <CurrentCompany />
            </DealerRoute>
          }
        />
        <Route path="/test" element={<Test />} />
        <Route element={<Dashboard />} index />
      </Routes>
    </AuthRoute>
  </NavPage>
);

const AppRoutes = () => (
  <BrowserRouter>
    <App>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/register" element={<Register />} />
        <Route path="/pay" element={<PaymentPage />} />
        <Route path="/worldnet/receipt" element={<WorldNetReceipt />} />
        <Route path="/worldnet/token" element={<WorldNetTokenResponse />} />
        <Route element={<NavPageRouter />} path="/*" />
      </Routes>
    </App>
  </BrowserRouter>
);

export default AppRoutes;
