import { ReactNode } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { companyAtom } from '../../stores/root.store';
import '../../styles/login.scss';
import { useAtom } from 'jotai';

interface IProps {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  children: ReactNode;
  className?: string;
  style?: any;
}

const LoginPanel = ({ children, xs = 12, sm = 10, md = 8, lg = 6, className, style = {} }: IProps) => {
  const [company] = useAtom(companyAtom);

  return (
    <Row id="login-container" className={className} style={style}>
      <Col xs={xs} sm={sm} md={md} lg={lg}>
        <Card className="form-login">
          <Card.Body>
            <>
              <div className="company-logo-container">
                <img
                  alt="DSI"
                  className="company-logo"
                  src={company.logo || 'https://storage.googleapis.com/dsi-portal/assets/logos/dsi-logo.png'}
                />
              </div>
              {children}
            </>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default LoginPanel;
