import _ from 'lodash';
import LoginPanel from './LoginPanel';
import { useAtom } from 'jotai';
import BlazeForm from 'blaze-form';
import { toast } from 'react-toastify';
import { Button, Col, Row } from 'react-bootstrap';
import { AxiosError } from 'axios';
import Api from '../../services/ApiService';
import AuthService from '../../services/AuthService';
import { userAtom } from '../../stores/root.store';
import { Link, useNavigate } from 'react-router-dom';
import companyAtom from '../../stores/company.store';
import { LayoutItem } from 'blaze-validator';

const layout: LayoutItem[] = [
  { label: 'Email', name: 'username', type: 'email', required: true },
  { label: 'Password', name: 'password', type: 'password', required: true },
];

const customerLayout: LayoutItem[] = [
  { label: 'Customer Number', name: 'customerNum', required: true },
  { label: 'Invoice Number', name: 'invoiceNum', required: true },
];

const Login = () => {
  const [, setUserState] = useAtom(userAtom);
  const [company] = useAtom(companyAtom);
  const navigate = useNavigate();

  const handleSubmit = (formData: object) => {
    Api.post('login', formData)
      .then(({ data }) => {
        const { token, user, hostname } = data;
        AuthService.setToken(token);
        setUserState(user);

        if (hostname) {
          // setTimeout allows for any cookies to be set
          setTimeout(() => (window.location.href = hostname));
        } else {
          // couldn't get navigate to work here because user state is set async
          setTimeout(() => (window.location.href = '/'));
        }
      })
      .catch((err: AxiosError) => {
        const errorMsg = _.get(err, 'response.data.error');
        toast(errorMsg || 'Login Failed');
      });
  };

  const handleCheckCustomer = (formData: object) => {
    Api.post('/login-anon', formData)
      .then(({ data }) => {
        const { token, user } = data;
        AuthService.setToken(token);
        setUserState(user);

        // couldn't get navigate to work here because user state is set async
        setTimeout(() => (window.location.href = '/'));
      })
      .catch((err: AxiosError) => {
        const errorMsg = _.get(err, 'response.data.error');
        toast(errorMsg || 'Login Failed');
      });
  };

  const handleForgotPassword = () => {
    navigate('/forgot-password');
  };

  return (
    <LoginPanel>
      <h3>Log in to your account</h3>
      <Row>
        <Col xs={12} sm={company.hasPermission('customer.anonLogin') ? 6 : 12}>
          <BlazeForm layout={layout} onSubmit={handleSubmit} hideCancel hideSubmit submitAll>
            <div className="login-controls">
              <Button variant="primary" type="submit" block id="login-btn" data-blaze-submit>
                Login
              </Button>
              <Button variant="default" block onClick={handleForgotPassword} id="forgot-pw-btn">
                Forgot Password
              </Button>
            </div>
          </BlazeForm>
          <div className="extra-actions">
            Don't have an account? <Link to="/register">Request Access</Link>
          </div>
        </Col>
        {company.hasPermission('customer.anonLogin') && (
          <Col xs={12} sm={6}>
            <BlazeForm layout={customerLayout} onSubmit={handleCheckCustomer} hideCancel hideSubmit submitAll>
              <div className="login-controls">
                <Button variant="primary" type="submit" block data-blaze-submit>
                  Login
                </Button>
              </div>
            </BlazeForm>
          </Col>
        )}
      </Row>
    </LoginPanel>
  );
};

export default Login;
